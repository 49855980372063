import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { HomeScreen } from "../screens/private/HomeScreen";
import { ProfileScreen } from "../screens/private/ProfileScreen";
import { StatisticsScreen } from "../screens/private/StatisticsScreen";
import { useDispatch, useSelector } from "react-redux";
import { screens } from "../constants/NavigationConstants";
import { removeCheckingFallHerramientasBrands, removeCheckingFallHerramientasProducts, removeCheckingFallHerramientasProfit, removeCheckingFallHerramientasSold, saveFallHerramientasBrands, saveFallHerramientasProducts, saveFallHerramientasProfit, saveFallHerramientasSold } from "../actions/fallHerramientas";
import { StoreProps } from "../interfaces/ReducerInterfaces";
import { useEffect } from "react";
import { formatExpirationTime, getFullDate, reorderArray } from "../utils/Utilities";
import { getAuth } from "firebase/auth";
import { login } from "../actions/auth";
import { finishLoading, startLoading } from "../actions/ui";
import { collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase/firebase-config";
import { startGetMembersQuery } from "../queries/AuthQueries";

export const PrivateRouter = () => {

    const { name, uid, role, expirationTime, token } = useSelector((state: StoreProps) => state.auth);

    const dispatch = useDispatch()

    const auth = getAuth()

    useEffect(() => {

        const handleEvent = async () => {

            if (expirationTime && expirationTime < parseInt(getFullDate())) {

                dispatch(startLoading())

                const tokenResult = await auth.currentUser?.getIdTokenResult(true)

                if (tokenResult)
                    dispatch(login({
                        uid,
                        name,
                        token: tokenResult.token,
                        role: tokenResult.claims.role,
                        expirationTime: formatExpirationTime(tokenResult.expirationTime)
                    }))

                dispatch(finishLoading())

            }
        };

        window.addEventListener('click', handleEvent);
        window.addEventListener('keydown', handleEvent);

        return () => {
            window.removeEventListener('click', handleEvent);
            window.removeEventListener('keydown', handleEvent);
        };
    }, [auth.currentUser, dispatch, expirationTime, name, uid]);

    function productsSnapshot(snapshot: any) {

        let allProducts = snapshot.docs.map((doc: any) => {

            return {

                id: doc.id,
                ...doc.data()

            }

        });

        dispatch(saveFallHerramientasProducts(allProducts));

        dispatch(removeCheckingFallHerramientasProducts());

    }

    function brandsSnapshot(snapshot: any) {

        let allBrands = snapshot.docs.map((doc: any) => {

            return {

                id: doc.id,
                ...doc.data()

            }

        });

        dispatch(saveFallHerramientasBrands(reorderArray(allBrands)));

        dispatch(removeCheckingFallHerramientasBrands());

    }

    function soldSnapshot(snapshot: any) {

        let allSold = snapshot.docs.map((doc: any) => {

            return {

                id: doc.id,
                ...doc.data()

            }

        });


        dispatch(saveFallHerramientasSold(allSold));

        dispatch(removeCheckingFallHerramientasSold());

    }

    function profitSnapshot(snapshot: any) {

        let config = snapshot.docs.map((doc: any) => {

            return {

                id: doc.id,
                ...doc.data()

            }

        });

        dispatch(saveFallHerramientasProfit(config[0].profit));

        dispatch(removeCheckingFallHerramientasProfit());

    }

    useEffect(() => {

        onSnapshot(collection(firestore, "Products"), productsSnapshot);

        onSnapshot(collection(firestore, "Brands"), brandsSnapshot);

        onSnapshot(collection(firestore, "Sold"), soldSnapshot);

        onSnapshot(collection(firestore, "Config"), profitSnapshot);

        return () => {

            onSnapshot(collection(firestore, "Products"), productsSnapshot);

            onSnapshot(collection(firestore, "Brands"), brandsSnapshot);

            onSnapshot(collection(firestore, "Sold"), soldSnapshot);

            onSnapshot(collection(firestore, "Config"), profitSnapshot);

        }

    }, [
        productsSnapshot,
        brandsSnapshot,
        soldSnapshot,
        profitSnapshot
    ]);

    return (

        <Switch>

            <Route
                exact
                path={`/${screens.home}`}
                component={HomeScreen}
            />

            {
                role === 'Administrador' && (

                    <Route
                        exact
                        path={`/${screens.statistics}`}
                        component={StatisticsScreen}
                    />

                )
            }

            <Route
                exact
                path={`/${screens.profile}`}
                component={ProfileScreen}
            />

            <Redirect to={`/${screens.home}`} />

        </Switch>

    )
}
