import { getAuth } from "firebase/auth";

export const getFullDate = () => {

    const date = new Date();

    let fullDate = '';
    const year = date.getFullYear();
    const month = formatTwoNumbers(date.getMonth() + 1);
    const day = formatTwoNumbers(date.getDate());
    const hours = formatTwoNumbers(date.getHours());
    const minutes = formatTwoNumbers(date.getMinutes());
    const seconds = formatTwoNumbers(date.getSeconds());
    const miliseconds = fillWithCeros(date.getMilliseconds().toString(), 3);

    fullDate = `${year}${month}${day}${hours}${minutes}${seconds}${miliseconds}`;

    return fullDate;

}

export const getDate = () => {

    const date = new Date();

    let fullDate = '';
    const year = date.getFullYear();
    const month = formatTwoNumbers(date.getMonth() + 1);
    const day = date.getDate();

    fullDate = `${year}${month}${day}`;

    return fullDate;

}

export const getMonth = () => {

    const date = new Date();

    const month: string = formatTwoNumbers(date.getMonth() + 1);

    return month;

}

export const getYearMonth = () => {

    const date = new Date();

    let fullDate = '';
    const year = date.getFullYear();
    const month = formatTwoNumbers(date.getMonth() + 1);


    fullDate = `${year}${month}`;

    return fullDate;

}

const formatTwoNumbers = (month: any) => {

    let m = month;

    if (m < 10) {

        m = `0${m}`;

    }

    return m;

}

export const formatPrice = (price: number) => {

    let p: number = price;
    const internationarFormat = new Intl.NumberFormat('de-DE')

    const result: string = internationarFormat.format(p);

    return result;

}

export const formatPriceEN = (price: number) => {

    let p: number = price;
    const internationarFormat = new Intl.NumberFormat('en-EN', { maximumFractionDigits: 2 })

    const result: string = internationarFormat.format(p);

    return result;

}

export const formatDateDDmmYYYY = (date: number) => {

    let d = date.toString().slice(6, 8);
    let m = date.toString().slice(4, 6);
    let y = date.toString().slice(0, 4);

    return `${d}/${m}/${y}`;

}

export const formatDateYYYYmmDD = (date: number) => {

    let d = date.toString().slice(6, 8);
    let m = date.toString().slice(4, 6);
    let y = date.toString().slice(0, 4);

    return `${y}-${m}-${d}`;

}

export const formatTimeTZ = (time: string) => {

    let m = time.slice(2, 5);
    let h = time.slice(0, 2);

    h = (parseInt(h) + 3).toString();

    if (parseInt(h) < 10) h = '0' + h

    return `T${h}${m}:00.000Z`;

}

export const formatFullDateTimeTZ = (date: number, time: string) => {

    return formatDateYYYYmmDD(date) + formatTimeTZ(time);

}

export const formatDateYYYYmmDDhhMMssMMmm = (date: number) => {

    let ml = date.toString().slice(14, 17);
    let s = date.toString().slice(12, 14);
    let mi = date.toString().slice(10, 12);
    let h = date.toString().slice(8, 10);
    let d = date.toString().slice(6, 8);
    let m = date.toString().slice(4, 6);
    let y = date.toString().slice(0, 4);

    return `${y}-${m}-${d}-${h}-${mi}-${s}-${ml}`;

}

export const formatDateYYYYmmDDhhMMssMMmmText = (date: number) => {

    let ml = date.toString().slice(14, 17);
    let s = date.toString().slice(12, 14);
    let mi = date.toString().slice(10, 12);
    let h = date.toString().slice(8, 10);
    let d = date.toString().slice(6, 8);
    let m = date.toString().slice(4, 6);
    let y = date.toString().slice(0, 4);

    return `${d}/${m}/${y} - ${h}:${mi}:${s}:${ml}hs`;

}

export const formatDateText = (date: number) => {

    const newDate = new Date(formatDateYYYYmmDD(date));

    let dayOfWeek = '';
    let month = '';

    let d = date.toString().slice(6, 8);
    let m = date.toString().slice(4, 6);

    switch (newDate.getDay()) {
        case 0:
            dayOfWeek = 'Lun';
            break;
        case 1:
            dayOfWeek = 'Mar';
            break;
        case 2:
            dayOfWeek = 'Mie';
            break;
        case 3:
            dayOfWeek = 'Jue';
            break;
        case 4:
            dayOfWeek = 'Vie';
            break;
        case 5:
            dayOfWeek = 'Sab';
            break;
        case 6:
            dayOfWeek = 'Dom';
            break;
    }

    switch (m) {
        case '01':
            month = 'ene.';
            break;
        case '02':
            month = 'feb.';
            break;
        case '03':
            month = 'mar.';
            break;
        case '04':
            month = 'abr.';
            break;
        case '05':
            month = 'may.';
            break;
        case '06':
            month = 'jun.';
            break;
        case '07':
            month = 'jul.';
            break;
        case '08':
            month = 'ago.';
            break;
        case '09':
            month = 'sep.';
            break;
        case '10':
            month = 'oct.';
            break;
        case '11':
            month = 'nov.';
            break;
        case '12':
            month = 'dic.';
            break;

    }

    return `${dayOfWeek} ${d} ${month}`;

}

export const formatDateHourText = (date: number) => {

    const newDate = new Date(formatDateYYYYmmDD(date));

    let dayOfWeek = '';

    let min = date.toString().slice(10, 12);
    let h = date.toString().slice(8, 10);
    let d = date.toString().slice(6, 8);
    let m = date.toString().slice(4, 6);
    let y = date.toString().slice(0, 4);


    switch (newDate.getDay()) {
        case 0:
            dayOfWeek = 'Lun';
            break;
        case 1:
            dayOfWeek = 'Mar';
            break;
        case 2:
            dayOfWeek = 'Mie';
            break;
        case 3:
            dayOfWeek = 'Jue';
            break;
        case 4:
            dayOfWeek = 'Vie';
            break;
        case 5:
            dayOfWeek = 'Sab';
            break;
        case 6:
            dayOfWeek = 'Dom';
            break;
    }

    return `${dayOfWeek} ${d}/${m}/${y} - ${h}:${min}hs`;

}

export const formatFullDateText = (date: number) => {

    const newDate = new Date(formatDateYYYYmmDD(date));

    let dayOfWeek = '';
    let month = '';

    let min = date.toString().slice(10, 12);
    let h = date.toString().slice(8, 10);
    let d = date.toString().slice(6, 8);
    let m = date.toString().slice(4, 6);
    let y = date.toString().slice(0, 4);


    switch (newDate.getDay()) {
        case 0:
            dayOfWeek = 'Lun';
            break;
        case 1:
            dayOfWeek = 'Mar';
            break;
        case 2:
            dayOfWeek = 'Mie';
            break;
        case 3:
            dayOfWeek = 'Jue';
            break;
        case 4:
            dayOfWeek = 'Vie';
            break;
        case 5:
            dayOfWeek = 'Sab';
            break;
        case 6:
            dayOfWeek = 'Dom';
            break;
    }

    switch (m) {
        case '01':
            month = 'enero';
            break;
        case '02':
            month = 'febrero';
            break;
        case '03':
            month = 'marzo';
            break;
        case '04':
            month = 'abril';
            break;
        case '05':
            month = 'mayo';
            break;
        case '06':
            month = 'junio';
            break;
        case '07':
            month = 'juio';
            break;
        case '08':
            month = 'agosto';
            break;
        case '09':
            month = 'septiembre';
            break;
        case '10':
            month = 'octubre';
            break;
        case '11':
            month = 'noviembre';
            break;
        case '12':
            month = 'diciembre';
            break;

    }

    return `${dayOfWeek} ${d} ${month} de ${y}, ${h}:${min}hs`;

}

export const formatMonthYearText = (date: number) => {

    let month = '';

    let m = date.toString().slice(4, 6);
    let y = date.toString().slice(0, 4);

    switch (m) {
        case '01':
            month = 'enero';
            break;
        case '02':
            month = 'febrero';
            break;
        case '03':
            month = 'marzo';
            break;
        case '04':
            month = 'abril';
            break;
        case '05':
            month = 'mayo';
            break;
        case '06':
            month = 'junio';
            break;
        case '07':
            month = 'juio';
            break;
        case '08':
            month = 'agosto';
            break;
        case '09':
            month = 'septiembre';
            break;
        case '10':
            month = 'octubre';
            break;
        case '11':
            month = 'noviembre';
            break;
        case '12':
            month = 'diciembre';
            break;

    }

    return `${month} ${y}`;

}

export const fillWithCeros = (inputString: string, length: number) => {
    // Verificar la longitud actual del string
    if (inputString.length < length) {
        // Calcular la cantidad de ceros que se deben agregar
        const cerosLeft = length - inputString.length;

        // Completar con ceros a la izquierda
        const string = '0'.repeat(cerosLeft) + inputString;

        return string;
    } else {
        // Si ya tiene 12 caracteres o más, devolver el string original
        return inputString;
    }
}

export const formatExpirationTime = (expirationTime: string) => {

    const a = expirationTime
    const fecha = new Date(a);

    // Obtener los componentes de fecha
    const año = fecha.getFullYear();
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Los meses en JavaScript van de 0 a 11
    const dia = fecha.getDate().toString().padStart(2, '0');
    const hora = fecha.getHours().toString().padStart(2, '0'); // Utilizar directamente la hora sin ajustes
    const minutos = fecha.getMinutes().toString().padStart(2, '0');
    const segundos = fecha.getSeconds().toString().padStart(2, '0');
    const milisegundos = fecha.getMilliseconds().toString().padEnd(3, '0');

    // Formatear la salida
    const formato = año + mes + dia + hora + minutos + segundos + milisegundos;

    return parseInt(formato) - 3000000
}

export const getNewToken = () => {
    return new Promise(async (resolve, reject) => {
        // Hacer una solicitud fetch a una URL
        const auth = getAuth()

        return await auth.currentUser?.getIdTokenResult(true)
            .then(tokenResult => {

                return tokenResult
            })
            .then(data => {
                setTimeout(() => {
                    console.log('Función asíncrona dentro del evento click');
                    resolve(data);
                }, 500); // Espera 1 segundo (1000 milisegundos)
            })
            .catch(error => {
                reject(error);
            });
    });
};

export const reorderArray = (arr: any) => {
    const newOrder = [1, 2, 8, 3, 4, 5, 7, 6];

    return newOrder.map(id => arr.find((item: any) => item.id === id));
};